<template>
    <div class="box">
        <el-form ref="form" :model="form" label-width="100px">
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="店铺账户">
                  <el-input v-model="form.businessAccount"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="店铺密码">
                  <el-input v-model="form.businessPassword"></el-input>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="店铺头像">
                     <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess1"
                        >
                        <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="店铺名称">
                  <el-input v-model="form.businessName"></el-input>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="店铺地区">
                  <el-input v-model="form.provinceName"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="业务管理">
                  <el-input v-model="form.name"></el-input>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="店铺分类">
                  <el-input v-model="form.industryType"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="营业时间">
                  <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期"
                      end-placeholder="结束日期"  />
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="代理商名称">
                  <el-input v-model="form.agentName"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="联系电话">
                  <el-input v-model="form.headPhone"></el-input>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="连锁编码">
                  <el-input v-model="form.chainCode"></el-input>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                
            </el-col>
            
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="身份证">
                     <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess2"
                        >
                        <img v-if="form.idCardImgFront" :src="form.idCardImgFront" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
            
            <el-col :span="9">
                <el-form-item label="身份证反面">
                    <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess6"
                        >
                        
                        <img v-if="form.idCardImgReverse" :src="form.idCardImgReverse" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">
            <el-col :span="9">
                <el-form-item label="资质许可证">
                    <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess4"
                        >
                        
                        <img v-if="form.qualifications" :src="form.qualifications" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
                <el-form-item label="经营场景">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess5"
                        >
                        
                        <img v-if="form.environmentImg" :src="form.environmentImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
          </el-row>
          <el-row style="display: flex;justify-content: space-between;">

            <el-col :span="9">
                <el-form-item label="营业执照">
                      <el-upload
                        class="avatar-uploader"
                        :action="$uploadURL"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess3"
                        >
                        
                        <img v-if="form.licenceImg" :src="form.licenceImg" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
               </el-form-item>  
            </el-col>
            <el-col :span="9">
              
            </el-col>
          </el-row>
        
           <el-form-item style="text-align: center;">
             <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button>
             <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
             
           </el-form-item>
        </el-form>
    </div>
  </template>
  
  <script>
 export default {
 
  data() {
    return {
        form: {
          isChain:1,
          logoUrl: '',
          idCardImgFront:'',
          idCardImgReverse:'',
          licenceImg:'',
          qualifications:'',
          environmentImg:''
          
        },
        fileList:[],
        uploadURL :'http://113.44.32.145/api/upload/uploadFile',
        disabled:false,
        valuechange:''

    };
  },
  mounted() {
    if(this.$route.query.id){
      this.id = this.$route.query.id
      this.$http.post('/business/queryBusinessDetail',{
        id:this.id
      }).then(response => {
         this.form = response.data.data;
        
        
        
        
      })
    }
  },
  methods: {
    handleSuccess(response, file, fileList){
      console.log( response.data.url);
    },
    onSubmit(){
        
        
        var that = this;
        that.form.isChain = 1
        that.$http.post("/business/saveBusiness", that.form).then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.$router.push('/auditBusinessone')
          }
        });
    },
    change(){
      console.log(this.valuechange);
      this.from.businessStartTime = this.newDate(this.valuechange[0])
      this.from.businessEndTime = this.newDate(this.valuechange[1])
    },
    newDate(time){
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    handleAvatarSuccess1(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.logoUrl = file.response.data.url;
    },
    handleAvatarSuccess2(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.idCardImgFront = file.response.data.url;
    },
    handleAvatarSuccess3(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.licenceImg = file.response.data.url;
    },
    handleAvatarSuccess4(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.qualifications = file.response.data.url;
    },
    handleAvatarSuccess5(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.environmentImg = file.response.data.url;
    },
    handleAvatarSuccess6(res, file) {
      console.log(file.response.data.url,'88888888');
        this.form.idCardImgReverse = file.response.data.url;
    }
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    background-color: #fff;
    // height: 100vh;
    padding: 20px 100px;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #877b7b;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    // display: block;
  }
  </style>
  
import { render, staticRenderFns } from "./auditdetail.vue?vue&type=template&id=1d5a41e2&scoped=true"
import script from "./auditdetail.vue?vue&type=script&lang=js"
export * from "./auditdetail.vue?vue&type=script&lang=js"
import style0 from "./auditdetail.vue?vue&type=style&index=0&id=1d5a41e2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5a41e2",
  null
  
)

component.options.__file = "auditdetail.vue"
export default component.exports